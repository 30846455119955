<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'

import { buildQueryString } from '@/utils/urls'
import NavBar from '@/components/NavBar/index.vue'
import SideMenuMobile from '@/components/SideMenuMobile/index.vue'

const { tm, rt } = useI18n()

const isSideMenuOpen = ref(false)

const hasCompletedOnboarding = useCookie<boolean>('has_completed_onboarding')
const opportunityTypeCookie = useCookie<string | null>('onboarding_opportunity_type')
const deliveryTermCookie = useCookie<string | null>('onboarding_delivery_term')
const bedroomsNumberCookie = useCookie<number | null>('onboarding_bedrooms_number')

const items = computed(() => (tm('items') as Array<any>).map((item: any) => {
  const id = rt(item.id)
  let path = rt(item.path)

  if (id === 'onboarding') {
    if (hasCompletedOnboarding.value) {
      path += '?' + buildQueryString({
        step: opportunityTypeCookie.value === 'living' ? 4 : 3,
        opportunity_type: opportunityTypeCookie.value,
        delivery_term: deliveryTermCookie.value,
        bedrooms_number: bedroomsNumberCookie.value
      })
    } else {
      path += '?' + buildQueryString({ step: 1 })
    }
  }

  return {
    id,
    label: rt(item.label),
    path,
    class: rt(item.class),
    target: item.target
  }
}))

const openSideMenu = () => {
  isSideMenuOpen.value = true
}

const closeSideMenu = () => {
  isSideMenuOpen.value = false
}

const scrollTop = ref(0)
const showHeader = ref(true)

const handleScroll = () => {
  const currentY = window.scrollY

  if (currentY >= scrollTop.value) showHeader.value = false
  else showHeader.value = true

  scrollTop.value = currentY
}

onMounted(() => {
  window.addEventListener('scroll', () => handleScroll())
})

</script>

<script lang="ts">
export default {
  name: 'AppHeader'
}
</script>

<template>
  <header
    class="fixed top-0 left-0 w-full bg-white z-30 transition-all duration-300 h-64 overflow-y-hidden"
    :class="{ '!h-0': !showHeader}">
    <NavBar
      :items
      :is-side-menu-open
      @on-side-menu-click="openSideMenu" />

    <SideMenuMobile
      v-if="isSideMenuOpen"
      :items
      @on-side-menu-click="closeSideMenu" />
  </header>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
